import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPackage, PackageEdges } from './package-grid.interface';

export default (): PackageEdges[] => {
    const { allDirectusPackage } = useStaticQuery<AllDirectusPackage>(graphql`
    query {
      allDirectusPackage(filter: { type: { eq: "Limited Company" }}, sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            slug
            name
            description
            price {
              value
            }
            discounted_price {
              value
            }
            package_grid {
              included_items {
                sort
                directusId
                name
              }
            }
            namecheck_path
            checkout_path
            icon
            stacked_icon
          }
        }
      }
    }
  `);

    for (let i = 0; i < allDirectusPackage.edges.length; i += 1) {
        allDirectusPackage.edges[ i ].node.package_grid[ 0 ].included_items.sort((a, b) => a.sort - b.sort);
    }

    return allDirectusPackage.edges;
};
