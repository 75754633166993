import React from 'react';

import { Props } from './PriceWithoutVat.interface';

import s from './PriceWithoutVat.scss';

export default ({ price, postText }: Props): JSX.Element => (
    <>
    &pound;{price}
        { postText && (<>&nbsp;<small className={s.postText}>{postText}</small></>)}
    </>
);
