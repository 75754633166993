import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Markdown from 'components/common/markdown/Markdown';
import Modal from 'components/common/modal/Modal';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import bankingAdsHook from 'hooks/banking-ads/banking-ads.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props, Bank } from './BankAdsGrid.interface';

import s from './BankAdsGrid.scss';

export default ({ isHome = false }: Props): JSX.Element => {
    const slug = 'home-bank-ads';
    const { node } = getFieldBySlug(slug, sectionTitleHook());
    const banks = bankingAdsHook();

    const BankGridItem = ({ content, logo, bank_name, brand_color, logo_alt, background_image }: Bank) => (
        <Modal
            trigger={
                <BackgroundImage
                    className={`${s.bankAdsGrid__gridItem} ${s[ `bankAdsGrid__gridItem___${bank_name}` ]}`}
                    style={{
                        backgroundColor: brand_color,
                        // eslint-disable-next-line
                        backgroundPosition: `${Math.floor(Math.random() * (90 - 10)) + 10}% -${Math.floor(Math.random() * (390 - 100 + 1)) + 100}px`,
                        opacity: '0',
                    }}
                    fluid={background_image.localFile.childImageSharp.fluid}
                >
                    <i className={s.bankAdsGrid__gridItem_logo}>
                        <Img fixed={logo.localFile.childImageSharp.fixed} alt={logo_alt} />
                    </i>
                    <h3 className={s.bankAdsGrid__gridItem_title}>{bank_name}</h3>
                </BackgroundImage>
            }
        >
            <Markdown source={content} container />
        </Modal>
    );

    return (
        <>
            <SectionTitle
                title={node.heading}
                subtitle={node.subheading}
                align="center"
            />
            <div className={s.bankAdsGrid}>
                {banks.map(({ node }) => {
                    const { bank_name, brand_colour, logo, logo_alt_text, content, background_image } = node;
                    return (
                        <div key={bank_name}>
                            <BankGridItem
                                bank_name={bank_name}
                                brand_color={brand_colour}
                                logo={logo}
                                background_image={background_image}
                                logo_alt={logo_alt_text}
                                content={content}
                            />
                        </div>
                    );
                })}
            </div>
            { isHome && (
                <ButtonGroup block className={s.bankAdsGrid__cta}>
                    <Button
                        role="primary"
                        size="large"
                        onClick={() => scrollTo('#home-top')}
                    >
                        Get Started
                    </Button>
                </ButtonGroup>
            )}
        </>
    );
};
