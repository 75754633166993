import React from 'react';
import slugify from 'react-slugify';
import camelcase from 'camelcase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import Button from 'components/common/button/Button';
import { Link } from 'components/common/link/Link';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import packageGridHook from 'hooks/package-grid/package-grid.hook';

import s from './PackageGrid.scss';

export default (): JSX.Element => {
    const packages = packageGridHook();

    return (
        <div className={s.packageGrid}>
            {packages.map(({ node }) => (
                <div
                    key={`package-grid-package-${node.slug}}`}
                    className={s.packageGrid__package}
                >
                    <div className={s.packageGrid__header}>
                        <h3 className={s.packageGrid__name}>
                            <Link to={`/package/${node.slug}/`}>{node.name}</Link>
                        </h3>
                        <div className={s.packageGrid__prices}>
                            {node.discounted_price ? (
                                <>
                                    <span className={s.packageGrid__price}>
                                        <PriceWithoutVat price={node.discounted_price.value} />
                                    </span>
                                    <span className={s.packageGrid__price___discounted}>
                                        <span>
                                            <PriceWithoutVat price={node.price.value} />
                                        </span>
                                        <span>50% OFF</span>
                                    </span>
                                </>
                            ) : (
                                <span className={s.packageGrid__price}>
                                    <PriceWithoutVat price={node.price.value} />
                                </span>
                            )}
                        </div>
                    </div>
                    <ul className={s.packageGrid__items}>
                        {node.package_grid[ 0 ].included_items.map((item) => (
                            <li className={s.packageGrid__item} key={`package-${camelcase(node.name)}-item${item.directusId}`}>
                                <span className={s.packageGrid__iconWrap}>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className={s[ `packageGrid__icon___${camelcase(node.name)}` ]}
                                    />
                                </span>
                                {item.name}
                            </li>
                        ))}
                    </ul>
                    <div className={s.packageGrid__buttons}>
                        <Button
                            role="primary"
                            to={`/package/${node.slug}/`}
                            className={s.packageGrid__buyButton}
                            block
                        >
                            Get Started
                        </Button>
                    </div>
                    { node.discounted_price && (
                        <div className={s.packageGrid__specialOffer}>
                            <span className={s.packageGrid__specialOfferText}>Special Offer</span>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
