import React from 'react';

import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import Carousel, { Pane } from 'components/directus/carousel/Carousel';
import heroHook from 'hooks/hero/hero.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props, BlockProps } from './UniqueSellingPoints.interface';

import s from './UniqueSellingPoints.scss';

export default ({ slug }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, heroHook());

    const UniqueSellingPoint = ({ title, image, imageAlt, body, linkUrl }: BlockProps): JSX.Element => {
        if (!linkUrl) {
            return (
                <div className={`${s.block} ${s.blockNoLink}`}>
                    <img
                        src={image.data.full_url}
                        alt={imageAlt}
                        className={s.block__image}
                        style={{ width: '90px', height: '90px' }}
                        loading="eager"
                    />
                    <h2 className={s.block__title}>{title}</h2>
                    <Markdown className={s.block__body} source={body} container />
                </div>
            );
        }

        return (
            <div className={s.block}>
                <Link to={linkUrl} className={s.block__link}>
                    <img
                        src={image.data.full_url}
                        alt={imageAlt}
                        className={s.block__image}
                        style={{ width: '90px', height: '90px' }}
                        loading="eager"
                    />
                    <h3 className={s.block__title}>{title}</h3>
                    <Markdown className={s.block__body} source={body} container />
                </Link>
            </div>
        );
    };

    return (
        <div className={s.container}>
            <Carousel width={300} height={225} maxVisibleSlides={4} changeOnResize>
                {node.usps.map((usp) => (
                    <Pane key={`unique-selling-point-${usp.slug}`}>
                        <UniqueSellingPoint
                            title={usp.title}
                            image={usp.image}
                            imageAlt={usp.image_alt}
                            body={usp.content}
                            linkUrl={usp.link_url}
                        />
                    </Pane>
                ))}
            </Carousel>
        </div>
    );
};
