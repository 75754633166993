import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';

import Markdown from 'components/common/markdown/Markdown';
import Namecheck from 'components/directus/namecheck/Namecheck';
import heroHook from 'hooks/hero/hero.hook';
import { StoreProps } from 'store/reducers/namecheck/namecheck.interface';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './HeroNamecheck.interface';

import s from './HeroNamecheck.scss';

const HeroNameCheck = ({ slug, state, dispatch }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, heroHook());
    const { visibility } = state;
    const heroStyle = !visibility ? s.heroNamecheck__hero___hidden : '';

    return (
        <div className={s.heroNamecheck}>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={`${s.heroNamecheck__hero} ${heroStyle}`}>
                            <h1 className={s.heroNamecheck__heading}><Markdown source={node.heading} /></h1>
                            <h3 className={s.heroNamecheck__subheading}><Markdown source={node.subheading} /></h3>
                        </div>
                        <Namecheck home handleChange={(visibility: boolean) =>
                            dispatch({ type: 'UPDATE_NAMECHECKVISIBLE', payload: visibility })} />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};
export default connect((state: StoreProps) => ({
    state: { ...state.nameCheck },
}))(HeroNameCheck);
