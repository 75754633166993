import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import StarRatings from 'react-star-ratings';

import heroHook from 'hooks/hero/hero.hook';
import { getFieldBySlug } from 'utils/directus';
import { checkFloat } from 'utils/check-float';
import { Props } from './HeroBoxes.interface';

import s from './HeroBoxes.scss';

export default({ slug }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, heroHook());

    return (
        <div className={s.heroBoxes}>
            <Grid>
                <Row>
                    <Col xs={12} xl={11}>
                        <div className={s.heroBoxes__boxes}>
                            {node.hero_boxes.map((box) => (
                                <div key={box.slug} className={s.heroBoxes__box}>
                                    <div className={s.heroBoxes__top}>
                                        <img
                                            className={`${s.heroBoxes__logo} ${s[ `heroBoxes__logo___${box.review.slug}` ]}`}
                                            src={box.review.logo.localFile.childImageSharp.fixed.src}
                                            alt={`${box.review.provider} logo.`}
                                            loading="eager"
                                        />
                                        <div className={s.heroBoxes__stars}>
                                            <StarRatings
                                                rating={Number(box.review.score)}
                                                numberOfStars={5}
                                                starRatedColor="#f39c12"
                                                starEmptyColor="#c5c5c5"
                                                starDimension="27px"
                                                starSpacing="0"
                                                name="rating"
                                            />
                                        </div>
                                    </div>
                                    <div className={s.heroBoxes__bottom}>
                                        <div className={s.heroBoxes__reviews}>
                                            {parseInt(box.review.total_reviews, 10).toLocaleString()} reviews
                                        </div>
                                        <div className={s.heroBoxes__ratings}>
                                            Rated {checkFloat(box.review.score) ? `${box.review.score}.0` : box.review.score} out of
                                            &nbsp;{checkFloat(box.review.max_score) ? `${box.review.max_score}.0` : box.review.max_score}
                                        </div>
                                    </div>
                                    <a href={box.review.link} target="_blank" className={s.heroBoxes__link} rel="noreferrer">&nbsp;</a>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};
