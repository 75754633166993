import React from 'react';

import heroHook from 'hooks/hero/hero.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './Hero.interface';

import s from './Hero.scss';

export default ({ slug, children }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, heroHook());

    return (
        <div className={s.hero} id="home-top">
            <div className={s.hero__model}>
                <img
                    srcSet={node.model_image.data.full_url}
                    alt={node.model_image_alt}
                    style={{ maxWidth: node.model_image.width }}
                    loading="eager"
                />
            </div>
            <div className={s.overlay} />
            {children}
        </div>
    );
};
