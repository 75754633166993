import React from 'react';
import Markdown from 'components/common/markdown/Markdown';

import SectionTitle from 'components/directus/section-title/SectionTitle';
import faqPanelHook from 'hooks/faq-panel/faq-panel.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug, sort } from 'utils/directus';
import { Props } from './HomepageFaqs.interface';

import s from './HomepageFaqs.scss';

export default ({ slug }: Props): JSX.Element => {
    const faqs = sort(faqPanelHook().filter(({ node }) => node.slug === slug));
    const { node } = getFieldBySlug(slug === 'home' ? 'home-faqs' : 'scottish-faqs', sectionTitleHook());

    return (
        <div className={s.homepageFaqs}>
            <SectionTitle title={node.heading} align="center" />

            {faqs.map(({ node }) => (
                <div className={s.panel} key={`homepage-faq-panel-${node.directusId}`}>
                    <h3 className={s.panel__title}>{node.title}</h3>
                    <Markdown source={node.body} container />
                </div>
            ))}
        </div>
    );
};
